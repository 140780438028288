.ds-checkbox {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    border-radius: 4px;
}

input[type=checkbox] {
    accent-color: $primaryColor;
}

.ds-checkbox-text {
    color: $darkTextColor;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    font-family: "Poppins";
    text-wrap: nowrap;
}
.ds-cheked-item {
    border: solid 2px $primaryColor;
    border-radius: 6px;
}