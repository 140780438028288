.ds-info-card-item {
    border-radius: 4px;
    border: 1px solid #D3DDE7;
}

.ds-info-title {
    color: $greyBlue;
    font-family: "Poppins";
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.ds-info-response {
    color: $darkTextColor;
    font-family: "Poppins";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: justify;
}