.ds-privacy-policy-text {
    color: $white;
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.ds-privacy-policy-link {
    text-decoration-line: underline;
}