.ds-sl-filter-box {
    border-radius: 10px;
    border: 1px solid $white;
    background: $white;
    box-shadow: 0px 4px 13px 0px rgba(132, 155, 178, 0.20);
}

.ds-shit-status {
    border-radius: 16px;
    min-width: 89px;
    height: 24px;
    overflow: hidden;
}

.ds-sl-map-box {
    border-radius: 10px;
    border: 1px solid #E5E9EC;
    background: $white;
}

.ds-sl-button {
    max-width: 150px;
    background-color: transparent !important;
    color: $primaryColor;
    border-radius: 10px !important;
    border: 1px solid $primaryColor !important;
    height: 40px !important;
}

@media (min-width:768px) {
    .ds-shipment-list-infity-scroll-container {
        height: 63vh !important;
        overflow: scroll !important;
    }
}