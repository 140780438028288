.ps-sidebar-root {
    height: 100%;
    width: 80px !important;
    border-radius: 24px;
    border-color: $white !important;
}

.ps-sidebar-container {
    background-color: $white !important;
}

.css-ewdv3l {
    background-color: $white !important
}

.ps-submenu-content {
    background-color: $white !important;
}

@media (min-width:992px) {
    .ps-sidebar-container {
        border-radius: 24px;
    }

}

.css-1jp6z4y {
    border-radius: 22px !important;
}

.ds-sidebar-menu {
    height: 80%;
    overflow-x: auto;
}

.ds-sidebar-config-item {
    color: $greyBlue;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.ds-sidebar-link-item {
    color: $darkTextColor;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.ds-sidebar-existing-item {
    fill: $primaryColor !important;
}

.css-1t8x7v1>.ps-menu-button:hover,
.css-16jesut>.ps-menu-button:hover {
    border-radius: 55px;
    background-color: $grey !important;
}

.css-1tqrhto>.ps-menu-button:hover {
    background-color: $grey !important;
}