.ds-upload-file {
    height: 104px;
    flex-shrink: 0;
    border-radius: 20px;
    border: 1px dashed $greyBorder;
    background: $greyDark;
    cursor: pointer;
}

.ds-upload-file-title {
    color: $darkTextColor;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    font-family: "Poppins";
    line-height: normal;
}

.ds-file-uploaded-item {
    height: 71px;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid $greyBorder;
    background: $white;
}

.ds-file-uploaded-item-title {
    color: $greyBlue;
    font-size: 12px;
    font-style: normal;
    font-family: "Poppins";
    font-weight: 400;
    line-height: normal;
}

.sc-jlZhew {
    width: 100% !important;
}

.sc-iGgWBj {
    width: 100% !important;
}

label[for="avatar_loader-79fd-9508-94d0"] {
    color: $darkTextColor !important;
    font-family: "Poppins" !important;
}