.ds-select-square-item {
    width: 106px;
    height: 95px;
    flex-shrink: 0;
    border-radius: 15px;
    border: 1px solid $primaryColor;
    cursor: pointer;
}

.ds-select-square-item-title {
    font-size: 18px;
    font-style: normal;
    font-family: "Poppins";
    font-weight: 500;
    line-height: normal;
}

@media (max-width:768px) {
    .ds-select-square-item {
        width: 63px;
        height: 55px;
        flex-shrink: 0;
    }

    .ds-select-square-item-title {
        font-size: 12px;
    }
}