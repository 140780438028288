.ds-scroll-x {
    overflow-x: auto;
}

.menu-item {
    color: $darkTextColor;
    text-align: center;
    font-family: "Poppins";
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.menu-item:hover {
    color: $primaryColor;
}

.sb-avatar {
    cursor: pointer;
}

.sb-avatar__text {
    background-color: $primaryColor !important;
}

.react-pdf__Page__canvas {
    width: 100% !important;
}

.ds-circle-icon-layout {
    width: 27px;
    height: 27px;
    border-radius: 50%;
    background: $secondaryColor;
}

.react-pdf__Page__textContent {
    display: none !important;
}

.react-pdf__Page__annotations {
    display: none !important;
}

.nav-tabs {
    flex-wrap: nowrap !important;
    overflow: auto;
}

.nav-tabs::-webkit-scrollbar {
    display: none;
}

.nav-item {
    text-wrap: nowrap;
}

.ds-vertical-nav-item {
    color: $darkTextColor;
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
}

.ds-selected-vertical-tem,
.ds-vertical-nav-item:hover {
    border-radius: 10px;
    background: $white;
    font-weight: bold !important;
}

.ds-manage-profile-avatar {
    display: flex;
}

.css-t3ipsp-control {
    box-shadow: 0 0 0 1px $primaryColor !important;
}

.css-t3ipsp-control:hover {
    border-color: $primaryColor !important;
}

th {
    text-wrap: nowrap;
}

.ds-font-poppins {
    font-family: "Poppins";
}

.ds-app-layout {
    height: 88vh;
}

.ds-footer {
    height: 6vh !important;
}

.ds-not-found-404 {
    color: $primaryColor;
    font-family: "Poppins";
    font-size: 196px;
    font-weight: 600;
}

.ds-not-found-oops {
    color: $darkTextColor;
    font-family: "Poppins";
    font-size: 96px;
    font-weight: 600;
}

.ds-not-found-pnf {
    color: $darkTextColor;
    font-family: "Poppins";
    font-size: 36px;
    font-weight: 600;
    line-height: 165px;
}

.ds-not-found-btn {
    width: 145px;
    height: 40px;
    flex-shrink: 0;
    border-radius: 10px;
    background: $primaryColor;
}

.ds-box {
    border-radius: 10px;
    border: 1px solid rgba(132, 155, 178, 0.20);
}

.ds-not-found-layout {
    border-radius: 10px;
    background: $white;
}

.ds-toggled-line-content {
    border-radius: 12px;
    border: 1px solid #F2F7FD;
    background: $white;
}

@media (max-width:768px) {
    .ds-manage-profile-avatar {
        display: contents;
    }

    .ds-manage-profile-avatar-avatar {
        display: flex;
        justify-content: center;
    }

    .ds-app-layout {
        height: 86vh;
    }
}