::-webkit-scrollbar {
    width: 6px;
}

::-webkit-scrollbar-thumb {
    background: #849BB2;
    border-radius: 12px;
}

::-webkit-scrollbar-track {
    background: $secondaryColor;
}

@media (max-width:768px) {
    ::-webkit-scrollbar {
        width: 4px;
    }
}