.ds-nav-user-fullName-title {
    font-size: 14px;
    font-weight: bold;
}

.ds-nav-user-fullName-title,
.ds-nav-user-email {
    color: $darkTextColor;
    font-family: "Poppins";
    font-style: normal;
    line-height: normal;
}

.ds-nav-user-email {
    font-size: 12px;
    font-weight: 500;
}

.ds-nav-user-card {
    width: 265px;
}

.ds-nav-user-link {
    color: $textColor;
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    height: 40px;
    line-height: 36px;
}

.ds-nav-user-link:hover {
    border-radius: 5px;
    background: rgba(236, 244, 252, 0.50);
}