.ds-prev-next-button {
    width: 121px;
    height: 36px;
    flex-shrink: 0;
    border-radius: 7px;
    color: $white;
    font-size: 14px;
    font-family: "Poppins";
}

.ds-auth-button {
    height: 40px;
    flex-shrink: 0;
    border-radius: 40px;
    background: $primaryColor;
    color: $white;
}

.ds-button-disabled {
    $disabledOpacity: 0.4;
    background-color: #3AB4A080 !important;
    cursor: not-allowed;
}

.ds-auth-button-text {
    color: $white;
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.ds-clear-button {
    color: $primaryColor;
}

.ds-save-button {
    color: $white;
    background-color: $primaryColor;
}

.ds-clear-button,
.ds-save-button {
    height: 40px;
    flex-shrink: 0;
    padding: 0px 10px;
    width: 121px;
    border-radius: 7px;
    border: 1px solid $primaryColor;
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.ds-button {
    min-width: 152px;
    height: 45px;
    flex-shrink: 0;
    border-radius: 10px;
    color: $white;
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.ds-primary-button {
    background: $primaryButtonColor;
}

.ds-secondary-button {
    background-color: $white;
    color: $primaryColor;
    border: 2px solid $primaryColor;
    font-weight: bold;
}

@media (max-width:768px) {
    .ds-prev-next-button {
        height: 32px;
    }
}