.ds-auth-form-footer {
    color: $darkTextColor;
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.ds-agree-term-auth {
    color: $darkTextColor;
    font-family: "Poppins";
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.ds-auth-logo {
    justify-content: center;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.ds-auth-layout {
    background: url('../../../assets/img/colisnet-auth-second-bg.png');
    background-size: cover;
    background-attachment: fixed;
}

.ds-auth-img {
    width: 125px;
}

.ds-auth-card {
    min-height: 100% !important;
}

.ds-auth-card-layout-global,
.ds-auth-card-layout-register {
    width: 90% !important;
}

@media (min-width:768px) {
    .ds-auth-img {
        width: 160px;
    }

    .ds-auth-card-layout-register {
        width: 50% !important;
    }

    .ds-auth-card-layout-global {
        width: 25% !important;
    }

    .ds-auth-logo {
        justify-content: start;
        padding-inline-start: 0.75rem;
        padding-top: 0.5rem;
    }
}