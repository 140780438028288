.ds-card {
    border-radius: 24px !important;
    min-height: 400px;
    border-color: $white;
    background-color: $white !important;
}

.ds-card-header {
    background-color: $white !important;
    border-bottom: none !important;
    border-radius: 24px 24px 0px 0px !important;
}

.ds-card-footer {
    background-color: transparent !important;
    border-top: none !important;
    padding-bottom: 20px !important;
}

.ds-toggled-card-title {
    color: $darkTextColor;
    font-size: 18px;
    font-style: normal;
    font-family: "Poppins";
    font-weight: 500;
    line-height: normal;
}

.ds-toggled-card {
    border-radius: 12px !important;
    border: 2px solid $secondaryColor !important;
    background: $white;
    box-shadow: 0px 1px 3px 0px rgba(132, 155, 178, 0.30);
}

.ds-toggled-default-header {
    border-radius: 12px !important;
}

.ds-toggled-card-header {
    border-radius: 12px 12px 0px 0px !important;
    border-bottom: none !important;
}

.ds-scroll-card {
    height: 60vh;
    overflow-x: auto;
}

.ds-body-card-simulation-list {
    height: 75vh !important;
}

.ds-toggled-card-title:hover {
    color: $primaryColor;
}

.ds-card-page-title {
    color: $darkTextColor;
    font-family: "Poppins";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    text-transform: capitalize;
}

.ds-toggled-card-add-text {
    color: $primaryColor;
    text-align: center;
    font-family: "Poppins";
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
}

.card-header-tabs .nav-link.active {
    background-color: transparent !important;
}

.card-body {
    overflow: auto;
}

@media (max-width:768px) {
    .ds-card-page-title {
        font-size: 14px;
    }

    .ds-toggled-card-title {
        font-size: 14px;
    }

    .ds-toggled-default-header {
        height: 45px;
    }
}