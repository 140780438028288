.react-tel-input .form-control {
    border-radius: 40px !important;
    background-color: transparent !important;
    border: 1px solid rgba(105, 105, 116, 0.45) !important;
    padding-left: 52px !important;
    color: $textColor !important;
}

.flag-dropdown {
    background-color: transparent !important;
}

.react-tel-input .flag-dropdown {
    border-radius: 40px 0 0 40px !important;
    border-color: transparent !important;
}

.form-control {
    width: 100% !important;
    height: 40px !important;
}

.react-tel-input .selected-flag {
    width: 42px !important;
    padding: 0 0 0 11px !important;
}

@media (max-width:768px) {
    .form-control {
        height: 35px !important;
    }
}