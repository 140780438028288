.ds-card-info {
    border-radius: 5px;
    background: rgba(0, 79, 158, 0.05);
    min-height: 108px;
}

.ds-info-text {
    color: $darkTextColor;
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
}

.ds-info-icon {
    font-size: 30px;
    line-height: 25px;
}