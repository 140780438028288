.ds-page-title {
    color: $darkTextColor;
    font-size: 24px;
    font-style: normal;
    font-family: "Poppins";
    font-weight: bold;
    line-height: normal;
    text-transform: capitalize;
    text-wrap: nowrap;
}

.ds-page-sub-title {
    color: #849BB2;
    font-family: "Poppins";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 186%;
}

.ds-title-card {
    font-size: 18px !important;
}

@media (max-width:768px) {
    .ds-page-title {
        font-size: 20px;
    }
}