.ds-input-select__control {
    height: 40px !important;
    flex-shrink: 0 !important;
    border-radius: 40px !important;
    border: 1px solid $greyBorder !important;
    background: transparent !important;
}

.css-13cymwt-control {
    background-color: transparent !important;
    border: 1px solid rgba(105, 105, 116, 0.45) !important;
}

.css-1fdsijx-ValueContainer {
    padding-left: 25px !important;
}

.css-13cymwt-control {
    border-radius: 40px !important;
    cursor: pointer !important;
    height: 40px;
}

@media (max-width:768px) {
    .css-13cymwt-control {
        height: 35px;
    }
}