.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    border-color: transparent !important;
    color: $primaryColor;
    border-bottom: 3px solid $primaryColor !important;
}

.nav-tabs .nav-link {
    color: $darkTextColor;
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
    border-color: transparent !important;
    color: $primaryColor !important;
    border-bottom: 3px solid $primaryColor !important;
}