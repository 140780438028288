.ds-uas-layout {
    border-radius: 10px;
    background: $white;
    min-height: 65px;
}

.ds-uas-title {
    color: $darkTextColor;
    text-align: center;
    font-family: "Poppins";
    font-size: 13px;
    font-weight: 600;
    text-align: start;
}

.ds-uas-sub-title {
    color: #696974;
    font-family: "Poppins";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.ds-uas-status {
    text-align: center;
    font-family: "Poppins";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: start;
}