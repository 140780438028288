#RFS-LabelContainer {
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

#RFS-StepperContainer {
    padding: 0px !important;
}

#RFS-StepperContainer {
    overflow-x: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

#RFS-StepperContainer::-webkit-scrollbar {
    display: none;
}

@media (min-width:768px) {
    .StepMain-0-2-3 {
        flex-direction: row !important;
        align-items: start !important;
    }

    #RFS-Label {
        margin-top: 0px !important;
        line-height: 32px;
    }

    #RFS-LabelContainer {
        width: auto !important;
        padding-left: 5px;
    }

    #RFS-StepContainer {
        width: auto;
    }

    #RFS-ConnectorContainer {
        display: none !important;
    }
}

@media (max-width:786px) {
    #RFS-Label {
        font-size: 10px;
    }

}