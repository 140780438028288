.ds-package-not-found-box {
    border-radius: 50%;
    background-color: $grey;
    height: 180px;
    width: 180px;
    padding: 15px;
    background-color: rgba(0, 105, 209, 0.08);
    ;
    background-size: cover;
}

.ds-from-to-text-request-card {
    color: $darkTextColor;
    font-family: "Poppins";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.ds-title-response-request {
    color: #696974;
    font-family: "Poppins";
    font-size: 10px;
    font-weight: 500;
}

.ds-request-card-body {
    padding: 0px 10px !important;
}

.ds-unselected-request-card {
    border-radius: 5px;
    border: 1px solid #D3DDE7;
    background: $white;
}

.ds-unselected-request-card:hover,
.ds-selected-request-card {
    border-radius: 5px !important;
    border: 2px solid $primaryColor;
    background: #F5FFFD;
    box-shadow: 0px 2px 9px 1px rgba(0, 0, 0, 0.20);
}

.ds-create-package-button {
    width: 244px !important;
}

.ds-rfq-accept-btn,
.ds-rfq-decline-btn {
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    width: 111px;
    height: 45px;
}

.ds-rfq-decline-btn {
    border-radius: 10px !important;
    background: rgba(255, 0, 0, 0.05) !important;
    color: $dangerText;
}

.ds-rfq-accept-btn {
    border-radius: 10px !important;
    background: $primaryButtonColor !important;
}

.ds-rfq-header-title {
    font-size: 18px !important;
    line-height: 40px;
}

.ds-rfq-response-title {
    color: $darkTextColor;
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.ds-rfq-document-item {
    width: 92px;
    height: 105px;
    border-radius: 5px;
    background: rgba(236, 244, 252, 0.50);
}

.ds-document-text {
    color: $greyBlue;
    text-align: center;
    font-family: "Poppins";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.ds-rfq-from-to-si-title,.ds-rfq-from-to-si-sub-title {
    color: $greyBlue;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.ds-rfq-from-to-si-sub-title {
    font-size: 13px;
}

.ds-rfq-service-temp {
    color: $darkTextColor;
    font-family: "Poppins";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

@media (max-width:768px) {
    .ds-save-simulation-btn {
        width: 79px;
        height: 37px;
    }

    .ds-create-package-button {
        width: 181px !important;
        height: 31px !important;
        font-size: 12px !important;
    }

    .ds-add-document-button {
        width: 140px !important;
    }

    .ds-rfq-header-title {
        line-height: 0px;
    }
}