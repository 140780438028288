:root {
    --primary-color: #3AB4A0;
    --secondary-color: #ECF4FC;
    --danger-color: #FF0B0B;
    --black-color: #000;
    --white-color: #FFF;
    --warning-color: #FA8334;
    --text-color: #696974;
}

.dark-theme {
    --secondary-color: #1D2939;
    --white-color: #101828;
    --black-color: #FFF;
}

/* TOGGLED COLORS */
$primaryColor: var(--primary-color);
$secondaryColor: var(--secondary-color);
$darkTextColor: var(--black-color);
$white: var(--white-color);
$dangerText: var(--danger-color);
$warningColor: var(--warning-color);
$textColor: var(--text-color);

/* CONST COLORS */
$secondBlue: #0069D1;
$disabledColor: #97B1CC;
$grey: #ECF4FC;
$greyBlue: #849BB2;
$greyBorder: rgba(105, 105, 116, 0.45);
$greyDark: rgba(212, 232, 252, 0.37);
$greyLine :rgba(132, 155, 178, 0.50);
$secondPrimaryColor: #E6FBF8;
$grey-white: #F2F2F2;
$darkGrey: #787C80;
$primaryButtonColor: #45D6BE;
$grey-strok: #BBBBC0;
$mix-secondary-primary: rgba(69, 214, 190, 0.10);
$darkBlue: #004F9E;

.ds-dark-blue-color {
    color: $darkBlue !important;
}

.ds-text-color {
    color: $textColor !important
}

.ds-white-color {
    color: $white !important;
}

.ds-warning-color {
    color: $warningColor !important;
}

.ds-grey-color {
    color: $grey !important;
}

.ds-grey-strok-color {
    color: $grey-strok !important;
}

.ds-black-color {
    color: $darkTextColor !important;
}

.ds-danger-text {
    color: $dangerText !important;
}

.ds-grey-blue-color {
    color: $greyBlue !important;
}

.ds-primary-color {
    color: $primaryColor !important;
}

.ds-bg-grey-blue {
    background-color: $greyBlue !important;
}

.ds-bg-warning {
    background-color: $warningColor !important;
}

.ds-bg-primary {
    background-color: $primaryColor !important;
}

.ds-bg-second-blue {
    background-color: $secondBlue !important;
}

.ds-bg-disabled {
    background-color: $disabledColor !important;
}

.ds-bg-secondary {
    background-color: $secondaryColor !important;
}

.ds-bg-second-primary-color {
    background-color: $secondPrimaryColor !important;
}

.ds-bg-grey {
    background-color: $grey !important;
}

.ds-bg-white {
    background-color: $white !important;
}

.ds-bg-dark-blue {
    background-color: $darkBlue !important;
}