.ds-drawer-header-title {
    color: $darkTextColor;
    text-align: center;
    font-size: 20px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.ds-drawer-header-subTitle {
    color: $greyBlue;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-family: "Poppins";
    font-weight: 500;
    line-height: normal;
}

.ds-drawer-header-line {
    background: $greyLine;
}

.ds-drawer {
    padding: 2% 3%;
    background: linear-gradient(179deg, $primaryColor -7.48%, $white 7.15%);
    height: 100%;
}

.EZDrawer__container  {
    z-index: 99999999!important;
}

.ds-drawer-with-footer-layout {
    max-height: 72vh;
}

.ds-drawer-without-footer-layout {
    max-height: 87vh;
}

@media (min-width:768px) {
    .ds-drawer-with-footer-layout {
        max-height: 87vh;
    }

    .ds-drawer-without-footer-layout {
        max-height: 92vh;
    }
}