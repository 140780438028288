@media (min-width:768px) {
    .ds-mo-general-content {
        overflow: auto;
        height: 70vh;
    }
}

.ds-mof-add-adrs-btn {
    width: 223px;
    height: 40px;
}

.ds-mof-adrs-title {
    line-height: 45px;
}

.ds-mof-general-adress-modal {
    width: 1020px;
}

.ds-mof-modal-days-layout {
    width: 856px;
    border-radius: 17px;
    background: $secondaryColor;
}

@media (max-width:768px) {
    .ds-mof-add-adrs-btn {
        width: 80px;
        height: 36px;
    }

    .ds-mof-adrs-title {
        line-height: 35px;
    }
}