.animated-line-container {
    width: 100%;
    height: 4px;
    background-color: #f0f0f0;
    border-radius: 5px;
    overflow: hidden;
}

.animated-line {
    height: 100%;
    background-color: $primaryColor;
    transition: width 0.5s ease-in-out;
}