.dropdown-menu {
    padding: 0px !important;
}

.dropdown-item {
    background-color: $white !important;
}

.dropdown-item:focus {
    color: transparent !important;
    background-color: transparent !important;
}

.dropdown-menu {
    top: 1px !important;
}

.ds-dropdown-hovered:hover .dropdown-menu {
    display: block;
    opacity: 1;
}

.dropdown-item.active,
.dropdown-item:active {
    color: transparent !important;
    text-decoration: none;
    background-color: transparent !important;
}

.dropdown-item:focus,
.dropdown-item:hover {
    background-color: $white !important;
}

.dropdown-menu {
    padding: 0px !important;
}