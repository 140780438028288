.ds-shit-layout {
    border-radius: 10px;
    border: 1px solid #E5E9EC;
    background: $white;
    min-height: 158px;
}

.ds-shit-selected-layout {
    border: 3px solid $primaryColor;
}

.ds-shit-package-item {
    min-width: 60px;
    height: 20px;
    border-radius: 5px;
    font-size: 11px;
    line-height: 19px;
}

.ds-shit-price {
    border-radius: 16px;
    background: #F5F6F8;
    width: 89px;
    height: 24px;
    line-height: 22px;
    flex-shrink: 0;
    font-size: 12px;
    font-weight: 600;
}