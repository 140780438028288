.ds-manage-profile-button-upload-profile-img {
    width: 150px;
    height: 27px;
    border-radius: 5px;
    background: $primaryColor;
}

.ds-delete-profile-img {
    color: $dangerText;
    font-family: "Poppins";
    font-size: 12px;
    font-weight: 500;
}

.ds-image-support-text {
    color: $greyBlue;
    font-family: "Poppins";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 0px;
}

.ds-manage-profile-security-conditions {
    color: $textColor;
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.ds-activities-logout-btn {
    background-color: $mix-secondary-primary !important;
    width: 227px;
    height: 25px;
    border-radius: 8px !important;
}

.ds-manage-profile-modal-text {
    color: $darkTextColor;
    text-align: center;
    font-family: "Poppins";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}