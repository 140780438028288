button[data-test-id="button-primary"] {
    background-color: $primaryColor !important;
}

.react-joyride__beacon span:first-child {
    background-color: $primaryColor !important;
}

.react-joyride__beacon span:last-child {
    background-color: transparent !important;
    border: 2px solid $primaryColor !important;
}