

.spinner {
    border: 5px solid hsla(120, 100%, 25%, 0.2);
    border-top: 4px solid $primaryColor;
    border-radius: 50%;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}