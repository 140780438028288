.ds-input {
    height: 40px;
    flex-shrink: 0;
    border-radius: 40px;
    border: 1px solid $greyBorder;
    background-color: transparent !important;
    color: $textColor;
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 0px 25px;
}

.ds-auth-input {
    color: $darkTextColor !important
}

.dYrfwz {
    min-height: 40px !important;
    border-radius: 40px !important;
    padding: 0px 5px !important;
}

.jVORrT {
    fill: $darkTextColor !important;
    margin: 0px 10px 0px 10px !important;
}

.ds-title-text {
    color: $greyBlue;
    font-family: "Poppins";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.jiMOeR .wrapper {
    border: 1px solid rgba(105, 105, 116, 0.45) !important;
    height: 40px;
    background-color: transparent !important;
}

.ds-input-error-text {
    color: $dangerText;
    font-family: "Poppins";
    font-size: 12px;
}

.ds-input-valide-border {
    border: 1px solid $primaryColor !important;
}

.ds-input-danger-border {
    border: 1px solid $dangerText !important;
}

input:focus-visible {
    outline: none !important
}

.ewTnKk>svg {
    fill: $dangerText !important;
}

.ds-input-generate-password-text {
    font-size: 13px;
    color: $greyBlue;
    font-weight: bold;
    font-family: "Poppins";
    cursor: pointer;
}

.jiMOeR {
    z-index: 2;
}

.sc-gEvEer {
    background-color: $white !important;
    border-radius: 10px;
    border: 1px solid rgba(105, 105, 116, 0.45) !important;
    margin-top: 0.25rem !important;
}

.ds-auth-icon-password {
    position: absolute;
    cursor: pointer;
    top: 35px;
    font-size: 22px;
}

.ds-auth-icon-password-not-rtl {
    right: 19px;
}

.ds-auth-icon-password-rtl {
    left: 19px;
}

.dYrfwz>input {
    color: $textColor !important;
}

@media (max-width:768px) {

    .ds-input,
    .jiMOeR .wrapper {
        height: 35px !important;
    }

    .dYrfwz {
        padding-bottom: 8px !important;

    }

    .ds-auth-icon-password {
        top: 32px;
    }
}