.react-date-picker {
    width: 100%;
    height: 40px;
    border-radius: 40px !important;
    padding: 0px 15px !important;
}

.react-date-picker {
    border: 1px solid $greyBorder !important;
}

.react-date-picker__wrapper {
    border: none !important;
}

.react-date-picker__button:enabled:hover .react-date-picker__button__icon {
    stroke: $greyBorder !important;
}

.react-date-picker__clear-button svg,
.react-date-picker__button svg {
    stroke: $darkTextColor !important;
}

.react-date-picker__inputGroup {
    color: $darkTextColor !important;
}

.react-time-picker__wrapper {
    border-radius: 40px;
    border: 1px solid rgba(105, 105, 116, 0.45);
    background: $white;
    padding: 0px 10px;
}

.react-time-picker {
    height: 40px;
}

@media (max-width:768px) {
    .react-date-picker {
        height: 35px;
    }
}