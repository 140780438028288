.table {
    border: 1px solid $grey !important;
    box-shadow: 0px 2px 3px 0px rgba(132, 155, 178, 0.50) !important;
}

.ds-table-title {
    color: $darkTextColor;
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    text-align: center;
}

.ds-scroll-table {
    width: 100%;
    overflow: auto;
}

.ds-scroll-table::-webkit-scrollbar {
    display: none;
}

th,
td {
    background-color: $white !important;
    color: $darkTextColor !important;
}

.MuiBox-root,
.css-12mkuht-MuiList-root-MuiMenu-list,
.css-1atu56z-MuiPaper-root {
    background-color: $white !important;
}

.MuiSvgIcon-root,
.MuiButtonBase-root {
    color: $primaryColor !important;
}

.css-byenzh-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {
    background-color: $primaryColor !important;
}

.css-uqq6zz-MuiFormLabel-root-MuiInputLabel-root,
.css-1v8abvc-MuiInputBase-root-MuiOutlinedInput-root,
.css-118d58w-MuiButtonBase-root-MuiTableSortLabel-root .MuiTableSortLabel-icon,
.css-1j43wpm-MuiButtonBase-root-MuiTableSortLabel-root .MuiTableSortLabel-icon {
    color: $greyBlue !important;
}

.css-1f2oslk-MuiTypography-root,
.css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input,
.css-1xt1x16-MuiTypography-root {
    color: $darkTextColor !important;
}

.css-953pxc-MuiInputBase-root-MuiInput-root {
    color: $textColor !important;
}

.css-953pxc-MuiInputBase-root-MuiInput-root::after {
    border-bottom: 2px solid $primaryColor !important;
}