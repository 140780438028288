.ds-text-info {
    height: 40px;
    flex-shrink: 0;
    border-radius: 10px;
    background: $grey;
    padding: 10px 14px;
    line-height: 16px;
}

.ds-text-info-label {
    color: $greyBlue;
    text-align: center;
    font-size: 14px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
}