.ds-select-auth-type-layout {
    height: 40px;
    flex-shrink: 0;
    border-radius: 40px;
    background: #F2F2F2;
}

.ds-selected-auth-type,
.ds-not-selected-auth-type {
    cursor: pointer;
    color: $darkTextColor;
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
}

.ds-selected-auth-type {
    border-radius: 40px;
    border: 2px solid #45D6BE;
    background: $white;
}