.ds-tracking-layout {
    background: url('../../../assets/img/tracking.png');
    background-size: cover;
    background-attachment: fixed;
    background-color: $grey;
    min-height: 100vh;
}

.ds-tracking-title,
.ds-tracking-subtitle {
    color: $darkTextColor;
    font-size: 32px;
    font-style: normal;
    font-weight: bold;
    line-height: 46px;
}

.ds-tracking-subtitle {
    font-weight: 300 !important;
}

@media (min-width:769px) {
    .ds-tracking-button-input-container {
        justify-content: space-between;
        display: flex;
    }

    .ds-tracking-button-container {
        margin-inline-start: 0.25rem;
    }
}

@media (max-width:768px) {
    .ds-tracking-button {
        min-width: 100px;
    }

    .ds-tracking-button-container {
        justify-content: center;
        display: flex;
        margin-top: 10px;
    }
}