.ds-sign-with {
    height: 40px;
    flex-shrink: 0;
    border-radius: 40px;
    background: $grey-white;
}

.ds-sign-with-text {
    color: #000;
    font-family: "Poppins";
    font-size: 14px;
    font-weight: bold;
    line-height: normal;
}