.ds-statistical-card-layout {
    height: 75px;
    border-radius: 10px;
    background: $white;
}

.ds-statistical-name {
    color: $darkTextColor;
    font-family: "Poppins";
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.ds-statistical-percentage {
    font-family: "Poppins";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    line-height: 32px;
}

.ds-dashboard-chart-layout {
    border-radius: 10px;
    background: $white;
    max-height: 320px;
    ;
}