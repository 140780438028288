.ds-device-card {
    border-radius: 5px;
    border: 1px solid #BBBBC0;
    height: 94px;
}

.ds-device-name-text,
.ds-my-device-text-check,
.ds-device-signout-text {
    font-family: "Poppins";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.ds-device-name-text {
    color: $darkTextColor;
}

.ds-my-device-text-check {
    color: $primaryColor;
}

.ds-device-info-text {
    color: $textColor;
    font-family: "Poppins";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.ds-device-signout-text {
    color: $textColor;
    font-weight: bold !important;
}