.ds-text-area {
    border-radius: 30px;
    border: 1px solid rgba(105, 105, 116, 0.45);
    background: $white;
    padding-left: 20px;
    padding-right: 20px;
}

.ql-toolbar {
    border-radius: 30px 30px 0px 0px;
}

.ql-container {
    border-radius: 0px 0px 30px 30px;
}

.ql-editor {
    min-height: 96px;
    color: $textColor !important;
}

textarea:focus-visible {
    outline: none !important;
}

.quill {
    border-radius: 30px;
}