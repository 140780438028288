.ds-modal {
    border-radius: 17px !important;
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.15) !important;
    background-color: $white !important;
    width: auto;
}

.ds-modal-title {
    font-family: "Poppins";
    font-size: 16px;
    font-weight: bold;
    color: $darkTextColor;
}

.ds-modal-header {
    border-bottom: none !important;
}

.ds-modal-footer {
    border-top: none !important;
}

.ds-submit-modal-btn,
.ds-cancel-modal-btn {
    border-radius: 7px !important;
    height: 36px;
}

.ds-submit-modal-btn {
    background: $primaryButtonColor !important;
    border: 1px solid $primaryButtonColor !important;
}

.ds-cancel-modal-btn {
    border: 1px solid $primaryButtonColor !important;
    color: $primaryButtonColor !important;
}