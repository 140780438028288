.ds-rectangle-item {
    height: 100px;
    flex-shrink: 0;
    border-radius: 10px;
    border: 2px solid $primaryColor;
    background: $white;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);
    padding: 5px 10px;
}

.ds-rectangle-item-title {
    color: $darkTextColor;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    font-family: "Poppins";
    line-height: normal;
}

.ds-retangle-item-desription {
    color: #849BB2;
    text-align: center;
    font-size: 13px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}