.ds-px-res-3 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
}

@media (min-width:768px) {
    .ds-md-mb-3 {
        margin-bottom: 1rem !important;
    }

    .ds-px-res-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important;
    }

    .ds-md-ms-2 {
        margin-inline-start: 0.5rem !important;
        margin-left: 0.5rem !important;
    }
}

@media (max-width:768px) {
    .ds-px-xs-0 {
        padding: 0px !important;
    }

    .ds-mb-xs-1 {
        margin-bottom: 0.25rem !important;
    }

    .ds-mb-xs-3 {
        margin-bottom: 1rem !important;
    }

    .ds-mb-xs-2 {
        margin-bottom: 0.5rem !important;
    }

    .ds-pre-line-xs {
        white-space: pre;
    }

}

@media (min-width:768px) {
    .ds-mb-md-1 {
        margin-bottom: 0.25rem;
    }

    .ds-mb-md-2 {
        margin-bottom: 0.5rem;
    }

    .ds-mb-md-3 {
        margin-bottom: 1.25rem;
    }

    .ds-mb-md-2 {
        margin-bottom: 1rem;
    }

    .ds-ps-md-5 {
        padding-inline-start: 3.25rem !important;
    }

}