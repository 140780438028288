@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'bootstrap/dist/css/bootstrap.min.css';
@import 'react-modern-drawer/dist/index.css';
@import 'react-quill/dist/quill.snow.css';
@import 'react-phone-input-2/lib/style.css';
@import 'react-date-picker/dist/DatePicker.css';
@import 'react-calendar/dist/Calendar.css';
@import 'react-time-picker/dist/TimePicker.css';
@import 'react-clock/dist/Clock.css';
@import 'react-toastify/dist/ReactToastify.css';
@import "https://cdn.jsdelivr.net/npm/driver.js@1.0.1/dist/driver.css";
@import './abstracts/index.scss';
@import './components/index.scss';
@import 'react-data-grid/lib/styles.css';
@import 'react-chat-elements/dist/main.css';
@import 'leaflet/dist/leaflet.css';
@import './components/spinner/Spinner.scss';

.ds-main {
    width: 100%;
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
}

@media (min-width:767px) {
    .ds-main {
        padding-right: 1rem !important;
        padding-left: 1rem !important;
    }
}