.ds-text-link {
    color: $primaryColor;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    font-family: "Poppins";
    line-height: normal;
    text-decoration-line: underline;
    text-wrap: nowrap;
}

.nav-tabs .nav-link.active {
    background-color: transparent !important;
}